.PaymentPage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 23px 0px;
}

.PaymentPage>div {
    width: 100% !important;
    min-width: 400px;
    max-width: 2300px;
}

.PaymentPage .title {
    text-align: center;
    font-weight: 800 !important;
    color: #25275F !important;
    margin-bottom: 17px !important;
    font-size: 43px;
    text-transform: uppercase;
}

.PaymentPage>div {
    background-size: 20px 20px;
    padding: 32px !important;
    border-radius: 23px;
}

.PaymentPage>div .inner {
    background-color: #fff !important;
    border-radius: 23px;
    height: auto;
    border-radius: 23px;
    text-align: left;
    padding: 1.5em;
    max-width: 800px !important;
    margin: 0px auto;
    /* box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.4); */
}

.PaymentPage .itemContainer {
    display: flex;
    align-items: center;
}

@media all and (max-width: 600px) {
    .PaymentPage .itemName {
        font-size: 0.8em;
        width: 5em;
    }
}


.PaymentPage .paypalAnimationContainer .paypalButtonText {
    display: none !important;
    visibility: hidden !important;
}


.PaymentPage table button {
    background-color: transparent !important;
}

.PaymentPage a {
    color: #635341 !important;
    font-size: 19px;
}

.PaymentPage .bottomContainer h3 {
    color: #32623e !important;
    font-weight: 600;
}

.PaymentPage .bottomContainer p {
    font-weight: 600 !important;
}

.PaymentPage .ant-radio-group {
    width: 100%;
}

.PaymentPage .ant-radio-button-wrapper {
    height: 60px !important;
    text-align: center !important;
    vertical-align: middle;
    min-width: 100px;
    width: 25% !important;
}

.PaymentPage .ant-radio-button-wrapper span {
    vertical-align: middle;
}

.PaymentPage .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #84c3e0;
    border-width: 2px;
    background-color: transparent;
    color: black;
}

.PaymentPage .paymentInformation {
    border: 1px solid #000 !important;
    border-radius: 8px;
    padding: 17px;
}

.PaymentPage .formPaymentContainer .sub-title {
    font-family: 'Libre Franklin', sans-serif !important;
    font-weight: 800 !important;
}

.PaymentPage .formPaymentContainer .paymentInformation {
    border: 6px solid #f0d000;
    border-radius: 10px;
}

.PaymentPage .formPaymentContainer .bottom {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PaymentPage .formPaymentContainer .bottom a {
    font-size: 15px;
    color: #5fa443 !important;
}

.PaymentPage .formPaymentContainer .bottom .btn {
    font-size: 15px;
    color: #FFFFFF;
    background-color: #488132 !important;
    padding: 8px 23px;
}

.PaymentPage .paymentInformation .formCheck {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 4px;
    margin-bottom: 17px;
}

.paypalImage img,
.paypalImage {
    width: 200px !important;
}

.PaymentPage .paymentInformation .payImages {
    display: flex !important;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.PaymentPage button#submit {
    margin: 30px 0px;
    width: 100%;
}

@media (max-width: 1100px) {
    .PaymentPage>div {
        width: 96% !important;
    }
}

@media (max-width: 600px) {
    .PaymentPage>div {
        padding: 19px !important;
    }

    .PaymentPage>div .inner {
        padding: 10px !important;
    }

    .PaymentPage .formPaymentContainer {
        width: 100% !important;
    }
}