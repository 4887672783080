.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 23px;
    border-bottom: 1px solid rgb(220, 218, 218);
}

.Navbar h3 {
    margin-bottom: 0px;
    color: rgb(15 23 42/1);
    font-weight: bold;
}

.Navbar div a {
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
}

.Navbar div a .anticon {
    color: rgb(15 23 42/1);
    font-size: 23px;
}

@media (max-width:600px) {
    .Navbar {
        padding: 12px;
    }
}