.dragger>div {
    background-color: transparent !important;
    /* margin-top: 32px; */
}

.dragger span[role="button"] {
    padding: 16px 24px !important;
}

.dragger span div {
    display: flex !important;
    align-items: center;
    /* width: 100%; */
}


.dragger .image {
    width: 60px !important;
    height: 60px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dragger .image-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 60px;
    height: 60px;
    margin-bottom: 23px;
}