@import 'antd/dist/antd.css';

body {
  margin: 0;
  min-height: 100vh;
}

html,
body {
  height: max-content !important;
}

body a {
  color: rgb(15 23 42/1);
}

.ant-message .anticon {
  vertical-align: text-top;
}

.anticon svg {
  vertical-align: text-top;
}

.ant-input {
  border-radius: 8px !important;
  background: #FFF !important;
  padding: 0px 13px;
  padding-left: 20px !important;
  height: 48px;
}

textarea.ant-input {
  padding-top: 8px !important;
  min-height: 130px;
}

.ant-select-selector,
.ant-picker {
  height: 48px !important;
  border-radius: 8px !important;
}

.ant-select {
  height: 48px !important;
}

.ant-select-selector {
  height: auto !important;
  padding: 10px 12px !important;
}

.ant-input-password {
  padding: 0px;
  padding-right: 11px;
  border-radius: 8px !important;
}

.ant-table-content {
  width: 100% !important;
  overflow-x: auto !important;
}

.textElipsisTwoLines {
  width: 100%;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.ant-btn {
  height: auto;
  padding: 10px;
  border-radius: 6px;
}

.ant-popover .ant-btn {
  padding: 3px 8px !important;
  border-radius: 2px !important;
}

.no-print {
  display: block;
}

@media print {
  .no-print {
    display: none !important;
    /* Ensure it overrides other styles */
  }
}