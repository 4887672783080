.ManageProfile {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
}

.ManageProfile>div {
    width: 100%;
    max-width: 600px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 30px;
}