/* ContractDetails.css */

.ContractDetails {
    max-width: 1200px;
    margin: 41px auto;
    padding: 20px;
    /* background-color: #f0f2f5; */
    /* border: 1px solid #dcdcdc; */
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.ContractDetails .header {
    background-color: #4CAF50;
    padding: 23px;
    width: 100%;
    text-align: center;
    margin-bottom: 23px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.ContractDetails .header h3 {
    color: white;
    margin-bottom: 0px;
}

.ContractDetails .contractFullDetails {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.ContractDetails .contractFullDetails .item {
    border-top: 1px solid gray;
    /* border-bottom: 1px solid gray; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.ContractDetails .contractFullDetails .item ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.ContractDetails .contractFullDetails .item h3 {
    margin-bottom: 0px;
}

.ContractDetails .contractFullDetails .item .items {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    flex-wrap: wrap;
    margin-top: 21px;
    width: 100%;
}

.ContractDetails .contractFullDetails .item .items .breakdown {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 23px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 21px 10px;
}

.ContractDetails .contractFullDetails .item .items h3 {
    font-size: 21px !important;
}

.ContractDetails .contractFullDetails .item .items h3 small {
    font-size: 14px !important;
    font-weight: 400;
}

.ContractDetails .contractFullDetails .item .items .breakdown h3 {
    max-width: 200px;
    word-wrap: break-word;
}

.ContractDetails .sign-section {
    margin-top: 20px;
}

.ContractDetails .sigCanvas {
    border: 1px solid #ccc;
    width: 100%;
    height: 200px;
    cursor: crosshair;
    /* Optional: Change cursor style */
}

/* Optional: Customize Ant Design modal styles */
.ant-modal-header {
    background-color: #1890ff;
    color: #fff;
}

.ant-modal-footer {
    border-top: 0;
}

.ContractDetails .signature {
    display: flex;
    justify-content: end !important;
    padding-top: 30px;
    border-top: 8px dashed gray;
}

.ContractDetails .signature img {
    height: 43px !important;
    width: auto;
}

@media (max-width: 600px) {
    .ContractDetails {
        padding: 20px !important;
    }
}