.auth {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 47px 0px !important;
}

.auth .auth-inner-bubble-container {
    width: 100% !important;
    max-width: 530px;
    background-color: white;
    padding: 23px 43px;
    box-shadow: 0 0 #000, 0 0 #0000, 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    border-radius: 10px;
}

.auth h2 {
    font-size: 36px !important;
    font-weight: 600 !important;
    color: black !important;
    text-align: center !important;
}

.auth h3 {
    color: black !important;
    text-align: center !important;
    width: 100%;
}

.auth .item {
    margin-top: 23px;
}

.auth p {
    color: black !important;
    text-align: center;
    font-weight: 500 !important;
    font-size: 17px;
    color: rgba(17, 17, 17, .4) !important;
}

.auth label {
    font-size: 17px;
    color: rgba(17, 17, 17, .4);
    display: block !important;
}

.auth .forgot-password {
    margin-top: 0px;
    text-align: end;
    color: black !important;
}

.auth .forgot-password a {
    color: black !important;
    text-decoration: underline;
}

.auth .end-text {
    display: flex;
    justify-content: center;
    gap: 6px;
    align-items: center;
    margin-top: 23px;
}

.auth a {
    color: black !important;
}

.auth input:focus {
    box-shadow: none !important;
}

@media (max-width: 700px) {
    .auth .auth-inner-bubble-container {
        width: 93% !important;
    }

    .auth h2 {
        font-size: 23px !important;
        margin-bottom: 15px !important;
    }

    .auth h3 {
        margin-bottom: 15px !important;
    }

    .auth .terms-privacy p {
        font-size: 14px;
    }
}