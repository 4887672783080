.ManageContracts {
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    /* max-width: 1200px; */
    margin: 20px auto;
}

.ManageContracts h2 {
    text-align: center;
    margin-bottom: 20px;
}

.ManageContracts form .ant-form-item-label {
    display: block;
    text-align: start !important;
    width: 100%;
}

.ManageContracts .items-section {
    margin-top: 20px;
    background-color: #fafafa;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.ManageContracts .items-section h3 {
    text-align: center;
    margin-bottom: 10px;
}

.ManageContracts .item-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ManageContracts .item-row input {
    margin-right: 10px;
    flex: 1;
}

.ManageContracts .item-row button {
    margin-left: 10px;
}

.ManageContracts .ql-editor {
    min-height: 300px;
}

.ManageContracts .paymentRecords h6 {
    width: 220px;
    word-wrap: break-word;
}

@media (max-width: 600px) {
    .ManageContracts {
        padding: 20px !important;
    }
}